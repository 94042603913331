<template>
  <div class="ss-common-title">
    <a-breadcrumb separator="/">
      <a-breadcrumb-item v-for="(item, idx) of titles" :key="idx">{{
        item.title || ''
      }}</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonTitle',
  props: {
    titles: { type: Array, default: () => [] }
  }
};
</script>

<style scoped lang="scss">
.ss-common-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px 20px 10px 30px;
}
</style>

