<template>
  <div class="create-course">
    <a-modal
      v-model="visible"
      width="1100px"
      title="添加课程"
      @ok="onOk"
      @cancel="onCancel"
    >
      <div class="search">
        <div class="left">
          <span>所属组织架构:</span>
          <span>
            <app-select-org v-model="search.org_id"/>
          </span>
          <span>所属岗位:</span>
          <span class="flex">
            <app-select-job v-model="search.post_id"/>
          </span>
          <span>
            <span>课程状态:</span>
            <span>
              <a-select
                v-model="search.status"
                style="width: 120px"
                :options="options"
              >
              </a-select>
            </span>
          </span>

          <span>
            <span>标题搜索:</span>
            <span>
              <a-input v-model="search.title" placeholder="请输入标题"/>
            </span>
          </span>
          <span>
            <a-button type="primary" @click="onSearch"> 搜索 </a-button>
          </span>
          <span>
            <a-button @click="onClearSearch"> 全部 </a-button>
          </span>
        </div>
        <!-- <div class="right">
          <a-button
            type="primary"
            @click="showCreateCoursePack(id)"
          > 新建课程包 </a-button>
        </div> -->
      </div>
      <div class="table">
        <a-table
          :columns="columns"
          :pagination="false"
          :data-source="data"
          :loading="loading"
          :scroll="{ y: 500 }"
          key="id"
          rowKey="id"
        >
          <template slot="action" slot-scope="text, record">
            <a @click="onJoinCoursePack(record)">加入课程包</a>
          </template>
          <template #footer>
            <div>
              <Pagination
                v-if="pagination && pagination.total"
                :pagination="pagination"
                class="pagination"
                @change="onPageChange"
                @showSizeChange="onSizeChange"
              ></Pagination>
            </div>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Pagination, { getPagination } from '@components/Pagination';
import {
  noJoinCoursePackTheCourseList,
  joinCoursePack,
} from '../api';

const columns = [
  {
    title: '类型',
    dataIndex: 'type_time',
    key: 'type_time',
    align: 'center',
  },
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '状态',
    key: 'status_name',
    dataIndex: 'status_name',
    align: 'center',
  },
  {
    title: '创建者',
    key: 'creator_name',
    dataIndex: 'creator_name',
    align: 'center',
  },
  {
    title: '发布时间',
    key: 'shutup_time',
    dataIndex: 'shutup_time',
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      data: [],
      columns,
      loading: false,
      id: 0,
      options: [
        {
          value: 'published',
          label: '已发布',
        },
        // {
        //   value: 0,
        //   label: '请选择'
        // },
        // {
        //   value: 1,
        //   label: '未审核'
        // },
        // {
        //   value: 2,
        //   label: '审核中'
        // },
        // {
        //   value: 3,
        //   label: '审核通过'
        // },
        // {
        //   value: 4,
        //   label: '下架'
        // }
      ],
      pagination: getPagination(),
      visible: false,
      search: {
        post_id: '',
        status: 'published',
        org_id: '',
        title: '',
        pack_id: 0,
        page: 1,
        per_page: 10,
      },
    };
  },
  methods: {
    show(id) {
      this.visible = true;
      this.clearSearch();
      this.search.pack_id = id;
      this.id = id;
      this.getList();
    },
    async getList() {
      const params = this.getParams();
      this.loading = true;
      const data = await noJoinCoursePackTheCourseList(params).finally(
        () => (this.loading = false),
      );
      if (data && data.error_code === 0) {
        this.data = data.data;
        this.setPagination(data.meta);
      }
    },
    async onJoinCoursePack(item) {
      let that = this;
      this.$confirm({
        content: () => <div>确认加入课程包?</div>,
        async onOk() {
          that.loading = true;
          const data = await joinCoursePack({
            pack_ids: that.id,
            course_id: item.id,
          }).finally(() => (that.loading = false));
          if (data && data.error_code === 0) {
            that.$message.success('添加成功');
            that.getList();
          } else {
            that.$message.error('添加失败', data.message);
          }
        },
      });
    },
    getParams() {
      this.search.page = this.pagination?.current;
      this.search.per_page = this.pagination?.pageSize;
      return this.search;
    },
    setPagination(meta) {
      if (meta) {
        this.pagination.current = meta?.pagination?.current_page ?? meta?.page;
        this.pagination.total =
          meta?.pagination?.total ?? this.pagination.total;
        this.pagination.pageSize = meta?.pagination?.per_page ?? meta?.size;
      }
    },
    clearSearch() {
      this.search = {
        post_id: '',
        status: 'published',
        org_id: '',
        title: '',
        page: 1,
        per_page: 10,
      };
      this.getList();
    },
    onSizeChange(page, size) {
      this.setPagination({ page, size });
      this.getList();
    },
    onPageChange(page, size) {
      this.setPagination({ page, size });
      this.getList();
    },
    onOk() {
      this.visible = false;
      this.$emit('onReLoad');
    },
    onCancel() {
      this.visible = false;
      this.$emit('onReLoad');
    },
    onSearch() {
      this.getList();
    },
    onClearSearch() {
      this.clearSearch();
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.create-course {
  padding: 20px;
}

.left {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-flow: wrap;
  flex: 1;
}

.right {
  width: 100px;
  text-align: center;
}

.search {
  display: flex;
  align-items: center;
  justify-items: center;

  span {
    display: inline-block;
    margin-right: 20px;
    white-space: nowrap;
    margin: 5px;

    .tree-select {
      width: 200px;
    }
  }
}

.table {
  margin: 40px 0 0 0;
}
</style>
