<template>
  <div class="ph-20">
    <div class="right-top-btn-group">
      <a-button type="primary" @click="toCreate()">
        新建课程包
      </a-button>
    </div>

    <div class="flex flex-start flex-wrap ph-20 mb-10">
      <div class="flex mr-30 mb-10">
        <span class="flex-none">所属组织架构：</span>
        <app-select-org v-model="search.org_id"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">所属岗位：</span>
        <app-select-job v-model="search.post_id"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">课程包状态：</span>
        <a-select v-model="search.status"
                  style="width: 120px"
                  :options="options">
        </a-select>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">标题搜索：</span>
        <a-input v-model="search.title" placeholder="请输入标题"/>
      </div>
    </div>
    <div class="flex flex-end flex-wrap ph-20 mb-10">
      <a-button type="primary" @click="onSearch">搜索</a-button>
      <a-button @click="onClearSearch">全部</a-button>
    </div>

    <a-table :columns="columns"
             :pagination="false"
             :data-source="data"
             :loading="loading"
             rowKey="id">
      <template slot="action" slot-scope="text, record">
        <a @click="toDetail(record.id)"> 管理项目 </a>
        <a @click="onUpPlacedTop(record)"> 更新推荐 </a>

        <a @click="upStatusByPackId(record)">
          {{ [2].includes(record.is_status) ? '下架' : '上架' }}
        </a>
        <a @click="onDelCoursePackById(record)"> 删除 </a>
      </template>
      <template #footer>
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>
  </div>
</template>

<script>
import {
  coursePackList,
  upPlacedTop,
  upStatusByPackId,
  delCoursePackById,
} from './api';

import Pagination, {
  getPagination,
  updatePagination,
} from '@components/table/Pagination.vue';

const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '课程数量',
    dataIndex: 'course_num',
    key: 'course_num',
    align: 'center',
  },
  {
    title: '状态',
    key: 'status_name',
    dataIndex: 'status_name',
    scopedSlots: { customRender: 'status_name' },
    align: 'center',
  },
  {
    title: '创建者',
    key: 'created_user_name',
    dataIndex: 'created_user_name',
    scopedSlots: { customRender: 'created_user_name' },
    align: 'center',
  },
  {
    title: '发布时间',
    key: 'release_at',
    dataIndex: 'release_at',
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  name: 'List',
  components: {
    Pagination,
  },
  data() {
    return {
      data: [],
      columns,
      loading: false,
      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '未发布',
        },

        {
          value: 2,
          label: '已发布',
        },
      ],
      pagination: getPagination(),
      search: {
        post_id: '',
        status: 0,
        org_id: '',
        title: '',
      },
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onReset() {
      this.search = {
        post_id: '',
        status: 0,
        org_id: '',
        title: '',
      };
      this.pagination = getPagination();

      this.onSearch();
    },
    onClearSearch() {
      this.onReset();
      this.getData();
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      const params = {
        ...this.search,
        page,
        per_page: pageSize,
      };
      this.loading = true;
      const data = await coursePackList(params).finally(
        () => (this.loading = false),
      );
      if (!data && data.error_code) {
        this.$message.error(data?.message || '获取课程包列表失败');
        return;
      }
      this.data = data.data;
      this.pagination = updatePagination(this.pagination, data?.meta?.pagination || {});
    },

    toCreate() {
      this.$router.push({
        path: '/index/course/package/create/basic',
      });
    },
    toDetail(id) {
      this.$router.push({
        path: '/index/course/package/detail/basic',
        query: {
          id,
        },
      });
    },
    // 置顶
    async onUpPlacedTop(item) {
      let that = this;
      this.$confirm({
        title: '确认提示',
        content: '确认更新推荐?',
        async onOk() {
          that.loading = true;
          const data = await upPlacedTop({
            pack_id: item.id,
            is_placed_top: 1,
          }).finally(() => (that.loading = false));
          if (data && data.error_code === 0) {
            that.$message.success('修改成功');
            that.getData();
          } else {
            that.$message.error('修改失败', data.message);
          }
        },
      });
    },
    async onDelCoursePackById(item) {
      let that = this;
      this.$confirm({
        title: '确认提示',
        content: `确认删除课程包?`,

        async onOk() {
          that.loading = true;
          const data = await delCoursePackById({
            id: item.id,
          }).finally(() => (that.loading = false));
          if (data && data.error_code === 0) {
            that.$message.success('删除成功');
            that.getData();
          } else {
            that.$message.error('删除失败', data.message);
          }
        },
      });
    },
    // 修改状态
    async upStatusByPackId(item) {
      const status = [2].includes(item.is_status) ? 2 : 3;
      let content = `确认${status === 2 ? '下架' : '上架'}?`;
      let that = this;
      if (status !== 2 && item.course_num <= 0) {
        that.$message.error('当前课程包课程数量为0,请添加课程!');
        return;
      }
      this.$confirm({
        title: '确认提示',
        content: content,
        async onOk() {
          that.loading = true;
          const data = await upStatusByPackId({
            id: item.id,
            status: status,
          }).finally(() => (that.loading = false));
          if (data && data.error_code === 0) {
            that.$message.success('修改成功');
            that.getData();
          } else {
            that.$message.error('修改失败', data.message);
          }
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.left {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-flow: wrap;
  flex: 1;
}

.right {
  width: 100px;
  text-align: center;
}

.search {
  display: flex;
  align-items: center;
  justify-items: center;

  span {
    display: inline-block;
    white-space: nowrap;
    margin: 5px;

    .tree-select {
      width: 200px;
    }
  }
}

i {
  font-style: normal;
}
</style>
