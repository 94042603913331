import request from '@/utils/Request';

// 课程包列表
export function coursePackList(params) {
  return request({
    url: '/course/admin/coursePackList',
    method: 'get',
    params,
  });
}

// 课程包详情
export function coursePackInfo(params) {
  return request({
    url: '/course/admin/coursePackInfo',
    method: 'get',
    params,
  });
}

// 课程包列表
export function noJoinCoursePackTheCourseList(params) {
  return request({
    url: '/course/admin/noJoinCoursePackTheCourseList',
    method: 'get',
    params,
  });
}

// 创建/修改课程包
export function addCoursePack(data) {
  return request({
    url: '/course/admin/addCoursePack',
    method: 'post',
    data,
  });
}

// 修改课程包状态
export function upStatusByPackId(data) {
  return request({
    url: '/course/admin/upStatusByPackId',
    method: 'post',
    data,
  });
}

// 删除
export function delCoursePackById(params) {
  return request({
    url: '/course/admin/delCoursePackById',
    method: 'delete',
    params,
  });
}

// 加入课程包
export function joinCoursePack(params) {
  return request({
    url: '/course/admin/joinCoursePack',
    method: 'post',
    params,
  });
}

// 课程移出课程包
export function moveOutCoursePackById(params) {
  return request({
    url: '/course/admin/moveOutCoursePackById',
    method: 'delete',
    params,
  });
}

// 课程包置顶
export function upPlacedTop(data) {
  return request({
    url: '/course/admin/upPlacedTop',
    method: 'post',
    data,
  });
}

// 拖动修改课程包内课程排序
export function saveSortNumInCoursePackTask(data) {
  return request({
    url: '/course/admin/saveSortNumInCoursePackTask',
    method: 'post',
    data,
  });
}

// 课程列表
export function coursePackTheCourseList(params) {
  return request({
    url: '/course/admin/coursePackTheCourseList',
    method: 'get',
    params,
  });
}

// 设置课程百分比
export function setDefaultCompleteConfig(data) {
  return request({
    url: '/course/admin/setDefaultCompleteConfig',
    method: 'post',
    data,
  });
}

// 课程百分比
export function getDefaultCompleteConfig(params) {
  return request({
    url: '/course/admin/getDefaultCompleteConfig',
    method: 'get',
    params,
  });
}
