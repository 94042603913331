<template>
  <div class="course-package-detail">
    <div class="flex flex-end">
      <a-button type="primary" v-if="id > 0" @click="showCreateCourse">
        添加新课程
      </a-button>
    </div>
    <div class="table">
      <a-table
        :columns="columns"
        :pagination="false"
        :data-source="data"
        :loading="loading"
        :customRow="customRow"
        rowKey="course_id"
        key="course_id"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="onMoveOutCoursePackById(record)">移出课程包</a>
        </template>
        <template #footer v-if="pagination && pagination.total">
          <div>
            <Pagination
              :pagination="pagination"
              class="pagination"
              @change="onPageChange"
              @showSizeChange="onSizeChange"
            ></Pagination>
          </div>
        </template>
      </a-table>
      <div class="btn-list">
        <a-button @click="onBack()"> 返回列表</a-button>
      </div>
    </div>

    <CreateCourse @onReLoad="onReLoad" ref="CreateCourseRef"></CreateCourse>
  </div>
</template>

<script>
import Pagination, { getPagination } from '@components/Pagination';
import {
  coursePackTheCourseList,
  moveOutCoursePackById,
  saveSortNumInCoursePackTask,
} from '../api';
import CreateCourse from '../components/CreateCourse.vue';

const columns = [
  {
    title: '排序',
    dataIndex: 'sort_num',
    key: 'sort_num',
    slots: { title: 'customTitle' },
    align: 'center',
  },
  {
    title: '类型',
    dataIndex: 'type_name',
    key: 'type_name',
  },
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '状态',
    key: 'status_name',
    dataIndex: 'status_name',
    align: 'center',
  },
  {
    title: '创建者',
    key: 'creator_name',
    dataIndex: 'creator_name',
    align: 'center',
  },
  {
    title: '发布时间',
    key: 'shutup_time',
    dataIndex: 'shutup_time',
    scopedSlots: { customRender: 'shutup_time' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  components: { Pagination, CreateCourse },
  data() {
    return {
      data: [],
      columns,
      loading: false,
      pagination: getPagination(),
      search: {
        page: 1,
        per_page: 10,
        pack_id: this.$route.query.id,
      },
      record: {},
      id: this.$route.query.id,
    };
  },
  methods: {
    async getList() {
      const params = this.getParams();
      this.loading = true;
      const data = await coursePackTheCourseList(params).finally(
        () => (this.loading = false),
      );
      if (data && data.error_code === 0) {
        this.data = data.data;
        if (data?.meta) {
          this.setPagination(data.meta);
        }
      }
    },
    async onMoveOutCoursePackById(item) {
      let that = this;
      this.$confirm({
        title: '确认提示',
        content: `确定移除课程包?`,
        async onOk() {
          that.loading = true;
          const data = await moveOutCoursePackById({
            pack_id: that.id,
            course_id: item.course_id,
          }).finally(() => (that.loading = false));
          if (data && data.error_code === 0) {
            that.$message.success('移出成功');
            that.getList();
          } else {
            that.$message.success('移出失败', data.message);
          }
        },
      });
    },
    async onSaveSortNumInCoursePackTask(ids) {
      const data = await saveSortNumInCoursePackTask({
        pack_id: this.id,
        ids: ids.toString(),
      }).finally(() => (this.loading = false));
      if (data && data.error_code === 0) {
        this.$message.success('操作成功');
      }
    },
    getParams() {
      this.search.page = this.pagination?.current;
      this.search.per_page = this.pagination?.pageSize;
      return this.search;
    },
    setPagination(meta) {
      if (meta) {
        this.pagination.current = meta?.pagination?.current_page ?? meta?.page;
        this.pagination.total =
          meta?.pagination?.total ?? this.pagination.total;
        this.pagination.pageSize = meta?.pagination?.per_page ?? meta?.size;
      }
    },
    showCreateCourse() {
      this.$refs.CreateCourseRef.show(this.id);
    },
    customRow(record, index) {
      return {
        // FIXME: draggable： true 不生效还不晓得是什么原因，先使用鼠标移入事件设置目标行的draggable属性
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer',
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            // 兼容IE
            var ev = event || window.event;
            ev.target.draggable = true;
          },
          // 开始拖拽
          dragstart: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            this.record = record;
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            var ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            this.arraySort(this.data, this.record, index);
          },
        },
      };
    },
    arraySort(list, record, index) {
      let result = [];
      let ids = [];
      const data = list.filter((s) => s.course_id !== record.course_id);
      this.data = [];
      let isPush = true;
      for (let i in data) {
        let sort_num = parseInt(i) + 1;
        if (i == index) {
          record.sort_num = sort_num;
          result.push(record);
          ids.push(record.course_id);
          isPush = false;
        }
        if (!isPush) {
          sort_num = sort_num + 1;
        }
        data[i].sort_num = sort_num;
        result.push(data[i]);
        ids.push(data[i].course_id);
      }
      if (isPush) {
        result.push(record);
        ids.push(record.course_id);
        isPush = false;
      }
      this.data = result;
      this.onSaveSortNumInCoursePackTask(ids);
    },
    onReLoad() {
      this.getList();
    },
    onPageChange() {
    },
    onSizeChange() {
    },
    onBack() {
      this.$router.push({
        path: '/index/course/course-package/',
        query: {
          id: this.data.id,
        },
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.course-package-detail {
  width: 100%;
}

.table {
  margin: 40px 0 0 0;
}

.btn-list {
  margin: 40px;
  text-align: center;
}
</style>
