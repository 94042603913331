<template>
  <div class="tab-detail-page full-page">
    <CommonTitle v-if="titles && titles.length" :titles="titles"/>

    <div class="flex space-between pd-card">
      <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
        <a-menu-item
          v-for="item of menus"
          :disabled="item.disabled"
          :key="item.id"
          >{{ item.title }}</a-menu-item
        >
      </a-menu>

      <slot name="extra"></slot>
    </div>

    <div class="flex pd-card">
      <router-view />
    </div>
  </div>
</template>

<script>
import CommonTitle from '@components/CommonTitle';

export default {
  name: 'TabDetailPage',
  components: { CommonTitle },
  props: {
    titles: { type: Array, default: () => [] },
    menus: {
      type: Array,
      default: () => [],
      // menu 必须具有一下几个属性
      // id 唯一标识
      // title 显示的文字
      // name 与路由相匹配的 name
      // path 跳转路径
      validator: (menus) =>
        menus &&
        menus.every((menu) =>
          ['id', 'title', 'name', 'path'].every((key) => !!menu[key])
        )
    },
    query: { type: Object, default: () => ({}) }
  },
  watch: {
    '$route.path'() {
      this.executeSelection(this.$route.name);
    }
  },
  data() {
    return {
      selectedMenu: this.menus[0]?.id ? [this.menus[0].id] : []
    };
  },
  created() {
    this.executeSelection(this.$route.name);
  },
  methods: {
    executeSelection(name) {
      this.menus.forEach((menu) => {
        if (menu.name === name) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({ key }) {
      const path = this.getPath(key);
      this.$router.replace({
        path,
        query: this.query
      });
    },
    getPath(key) {
      const [{ path }] = this.menus.filter(
        (menu) => menu.id === key + '' && menu.path
      );
      return path || '';
    }
  }
};
</script>

<style scoped lang="scss"></style>
